import React from 'react'
import IndexPageComponent from '../components'
import Layout from '../components/shared/layout'

const NeverHaveIEverPage = () => {
    return (
        <Layout isDark>
            <div className="square w-80vw h-80vw sm:w-70vw sm:h-70vw md:w-50vw md:h-50vw xl:w-35vw xl:h-35vw rounded flex justify-center items-center flex-col relative">
                <IndexPageComponent
                    policy="Политика конфиденциальности"
                    terms="Пользовательское соглашение"
                    language="ru"
                />
            </div>
        </Layout>
    )
}

export default NeverHaveIEverPage
